@import url("./calculateStyles.css");

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 0;
}

a {
  text-decoration: none;
  color: #1fb73a;
}

p {
  color: #fff;
  padding-left: 13px;
}

:host {
  --swiper-theme-color: #1fb73a !important;
}

.close_ic {
  top: 10px;
  right: 10px;
  position: absolute;
  background-color: white;
  border-radius: 100%;
  width: 20px;
}

body {
  margin: 0;
  padding: 0;
  background-color: #1c1c1c;
  background-size: 100% auto;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  min-width: 360px;
  overflow-x: hidden;
}

.container {
  min-height: 100vh;
  padding-bottom: 120px;
  box-sizing: content-box;
  max-width: 800px;
  margin: auto;
  position: relative;

  &.no-padding {
    padding: 0;
  }
}

.navigation {
  width: 100%;
  margin: 0px 0 12px;
  overflow-x: auto;
  align-items: center;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 11px -10px rgba(0, 0, 0, 0);
  display: flex;
  height: 50px;
  overflow-x: auto;
  padding: 0 8px;
  scroll-snap-type: none;
  -ms-overflow-style: none;
  user-select: none;
  scrollbar-width: none;
  box-sizing: border-box;
  background-color: #1c1c1c;
}

.navItem {
  align-items: center;
  font-size: 13px;
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  line-height: 0;
  height: 36px;
  justify-content: center;
  padding: 0 17px;
  position: relative;
  transition: color 0.3s;
  white-space: nowrap;
  z-index: 2;
  color: #969696;
  font-weight: 500;
  border-radius: 10px;
  transform: translateX(0px);
  transition: all 0.1s ease-out 0.01s;
  z-index: 1;
}

.active {
  background-color: #363636;
  color: #ffffff;
}

.title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 15px;
  padding: 8px 17px;
  width: 100%;
  top: 0;
  background: #1c1c1c;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title2 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 15px;
  width: 100%;
  top: 0;
  background: #1c1c1c;
  display: flex;
  gap: 12px;
  align-items: center;
}

.menuContainer {
  display: flex;
  padding: 0 4px;
  width: 100%;
  overflow: auto;
}

.menuItem {
  border-radius: 15px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 4px 8px;
  padding: 6px 6px 6px 6px;
  width: 30%;
  max-width: 171px;
  box-sizing: border-box;
  background-color: #363636;
}

.picture {
  border-radius: 15px 15px 7px 7px;
  border: none;
  line-height: 0;
  width: calc((100vw / 3) - 26px);
  height: calc((100vw / 3) - 26px);
  max-width: 159px;
  max-height: 159px;
  object-fit: cover;
}

.menuContainer2 .picture {
  border-radius: 15px 15px 7px 7px;
  border: none;
  line-height: 0;
  width: calc((100vw / 3) - 26px);
  height: calc((100vw / 3) - 26px);
  max-width: 100%;
  max-height: 159px;
  object-fit: cover;
}

.name {
  color: #ffffff;
  font-size: 13px;
  word-break: break-all;
  margin-bottom: 5px;
  padding: 0 5px;
  width: 100%;
  overflow: hidden;
  display: block;
}

.weight {
  color: #908f95;
  font-size: 12px;
  margin-bottom: 16px;
  padding: 0 5px;
  width: 100%;
}

.price {
  margin-top: auto;
  user-select: none;
  padding: 0 0 1px 0;
  width: 100%;
  background-color: #222222;
  color: #ffffff;
  box-shadow: none;
  align-items: center;
  border-radius: 11px;
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 0;
  justify-content: center;
  height: 34px;
}

.price-green-label {
  color: #1fb73a;
  font-size: 24px;
  font-weight: 700;
  line-height: 21px;
}

.old-gray-price {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  color: #7d7d7d;
  text-decoration-line: line-through;
}

.cic-minus,
.cic-plus {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 40px;
}

.cic-minus {
  box-shadow: inset 55px 0 20px -22px rgba(0, 0, 0, 0);
  padding-left: 13px;
}

.cic-plus {
  box-shadow: inset -55px 0 20px -22px rgba(0, 0, 0, 0);
  justify-content: flex-end;
  padding-right: 13px;
}

.amount {
  font-weight: 500;
}

.add {
  margin-top: auto;
  user-select: none;
  padding: 0 0 1px 0;
  width: 100%;
  background-color: #222222;
  color: #ffffff;
  align-items: center;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 1.2;
  height: 34px;
  justify-content: space-between;
  overflow: hidden;
}

.svg {
  width: 12px;
  border: none;
  line-height: 0;
  max-width: 100%;
  color: white;
}

.go-to-order-wrap {
  background-color: #2a2a2a;
  box-shadow: none;
}

.go-to-order-wrap {
  font-size: 13px;
  text-align: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 -6px 16px -16px #ababab;
  background-color: #2a2a2a;
  border-radius: 14px 14px 0 0;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1px auto;
  max-width: 480px;
  padding: 9px 12px 9px 12px;
  position: fixed;
  bottom: 75px;
  left: 0;
  right: 0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 101;
  color: white;
  max-width: 300px;
}

.container-food {
  top: 0;
  left: 0;
  z-index: 101;
  position: relative;
  max-width: 600px;
  margin: auto;
  background-color: #222222 !important;
  color: #ffffff;
  position: relative;
  box-shadow: 0 10px 20px -7px #000;
  height: 100vh;
  overflow-y: hidden;
  transition: transform 0.5s;
  box-sizing: content-box;
}

.container-food .foodPage .data-food {
  background-color: #222222 !important;
  padding: 0 0 100px;
  height: calc(100vh - 75px);
  overflow-x: hidden;
}

.container-food .foodPage .cross-item {
  display: flex;
  width: 51px;
  height: 51px;
  position: absolute;
  top: 18px;
  right: 18px;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.container-food .foodPage .cross-item svg {
  width: 100%;
  height: 100%;
}

.container-food .foodPage .food {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.container-food .foodPage .title-food {
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0 12px;
  padding: 0 17px;
  width: 100%;
}

.container-food .title-food .text-title-food {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
}

.container-food .title-food .weight-title-food {
  font-size: 20px;
  font-weight: 600;
  color: #908f95;
}

.container-food .foodPage .description-food {
  font-size: 14px;
  line-height: 1.4;
  padding: 0 15px;
  color: #ffffff;
}

.product-count-wrap {
  background-color: #262626;
  color: #ffffff;
  box-shadow: none;
  box-shadow: 0 -6px 16px -18px #9d9d9d;
  padding: 12px;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  margin: -1px auto;
}

.product-count-wrap .pcw-btn-group {
  display: flex;
  flex-wrap: wrap;
}

.product-count-wrap .pcw-btn-group .price-product-card {
  margin-right: 15px;
  display: flex;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
}

.product-count-wrap .pcw-btn-group .pcw-count-wrap {
  align-items: center;
  background: #363636;
  border-radius: 12px;
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  font-weight: 500;
  justify-content: space-between;
  margin-right: 10px;
  padding: 13px;
  width: 107px;
}

.product-count-wrap .pcw-count-wrap svg polygon,
.pcw-count-wrap svg rect {
  fill: #ffffff !important;
}

.product-count-wrap .pcw-count-wrap svg {
  width: 14px;
  border: none;
  line-height: 0;
  max-width: 100%;
}

.pcw-count-wrap .pcw-minus,
.pcw-plus {
  align-items: center;
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  justify-content: center;
}

.product-count-wrap .pcw-btn-group .gto-btn {
  flex-grow: 2;
  background-color: #1fb73a;
  color: #ffffff;
  display: block;
  border-radius: 12px;
  border: 1px solid #20b53a;
  font-size: 15px;
  font-weight: 500;
  padding: 14px;
  text-align: center;
}

.product-count-wrap .pcw-btn-group .gto-btn a {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-decoration: none;
  color: white;
}

.container-order {
  max-width: 600px;
  height: 100vh;
  margin: auto;
  background-color: #1c1c1c;
}

.container-order .page-title {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 15px 10px;
  padding-left: 0;
  color: #76757b;
  letter-spacing: 0.015em;

  .card-block-element-title {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    align-items: flex-end;
    padding-left: 10px;

    h3 {
      font-size: 24px;
      line-height: 24px;
      margin: 0;
      color: #d9d9d9;
    }
  }
}

.header-order {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  align-items: flex-end;
  padding-left: 10px;

  h3 {
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    color: #d9d9d9;
  }
}

.politic-block-text {
  max-width: 400px;
  margin: 0 auto;
  margin-top: 15px;
  text-align: center;
  padding-left: 0;
}

.section-label {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin: 20px 0;
}

.container-order .page-title .cart-empty {
  width: 21px;
  height: 21px;
  border: none;

  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-order .cart {
  background-color: #363636;
  border-radius: 13px;
  margin: 0 15px 120px;
  padding: 4px 0px 0px 0px;
  width: calc(100% - 30px);
}

.cart1 {
  margin: 0 15px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 4px 0px 0px 0px;
  padding-bottom: 200px;
  width: calc(100% - 30px);
  height: calc(100vh - 54px - 75px);
  overflow-y: auto;
}

.bottom-card-button {
  height: 75px;
  display: flex;
  align-items: center;
  padding: 13px 10px;
  background-color: #282828;
}

.container-order .cart-item-row {
  display: flex;
  padding: 0 0 0 13px;
  padding: 10px;
  border-radius: 13px;
  background-color: #363636;
  padding-bottom: 15px;
}

.container-order .cart-item-row .cart-item-picture {
  margin-right: 12px;
}

.container-order .cart-item-row .cart-item-picture img {
  border-radius: 9px;
  width: 80px;
  height: 100%;
  object-fit: cover;
}

.container-order .cart-item-row .cart-item-data {
  border-bottom-color: #363636;
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.container-order .cart-item-row .cart-item-data .cart-item-label {
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  letter-spacing: -0.05em;
  margin: 0;

  color: white;
}

.container-order .cart-item-row .cart-item-data .cart-item-caption {
  color: #9b9b9b;
  font-size: 14px;
}

.container-order .cart-item-row .cart-item-data .cart-item-label span {
  display: block;
  margin-top: 4px;
}

.container-order .cart-item-row .cart-item-data .cart-item-counter .cic-minus {
  padding-left: 11px;
}

.container-order .cart-item-row .cart-item-data .cart-item-counter {
  background-color: #222222;
  align-items: center;
  border-radius: 9px;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 0;
  justify-content: space-between;
  height: 34px;
  width: 100px;
}

.container-order .cart-item-row .cart-item-data .cart-item-counter .cic-count {
  font-weight: 500;
  color: white;
}

.container-order .cart-item-row .cart-item-data .cart-item-counter .cic-plus {
  padding-right: 11px;
  box-shadow: inset -55px 0 20px -22px rgba(0, 0, 0, 0);
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 40px;
}

.cart-item-counter svg {
  width: 10px;
}

.cart-item-counter svg polygon,
.cart-item-counter svg rect {
  fill: #ffffff !important;
}

.cart-item-tooltip {
  display: none;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  white-space: nowrap;
  height: 22px;
  background-color: #f1222e;
  padding: 0 10px;
  border-radius: 5px;
  position: absolute;
  top: 30px;
  right: 30px;
}

.container-order .cart-promocode-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: -109px;
  padding: 0 25px;
}

.container-order .cart-promocode-wrapper .cart-promocode-btn {
  font-size: 14px;
  color: #76757b;
  background-color: transparent;
  border: none;
  border-bottom: 1px dashed #a4a4a9;
}

.container-order-data {
  max-width: 600px;
  margin: auto;
  background-color: #1c1c1c;
  height: 100vh;
}

.logoIcon {
  width: 170px;
  height: 35px;
  cursor: pointer;
}

.order-form {
  padding: 20px 15px 20px;

  &.small {
    padding: 8px;
  }
}

.order-form-select-group .order-choise-btn-label {
  position: relative;
}

.order-form-select-group .order-choise-btn-label:first-child .choise-btn {
  background: url(../img/delivery.svg) no-repeat 16px center / 30px 30px,
    url(../img/chevron-right-dark.svg) no-repeat calc(100% - 16px) center / 15px
      15px;
  border-radius: 10px 10px 0 0;
}

.order-form-select-group .choise-btn:not(.choise-btn option) {
  padding-left: 62px;
  padding-right: 16px;
}

.order-form-select-group .order-choise-btn-label .choise-btn,
.order-form-select-group {
  background-color: #363636 !important;
}

.order-form-select-group {
  border-radius: 10px;
}

.order-form-select-group .order-choise-btn-label .choise-btn {
  outline: none;
  color: #fff;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0;
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  height: 45px;
  padding: 5px 20px;
  position: relative;
  user-select: none;
  transition: all 0.2s;
  appearance: none;
}

.order-form-select-group .order-choise-btn-label:first-child::after,
.order-form .form-group:after {
  background-color: #565656;
}

.form-search {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .form-search {
    display: block;
    /* При маленьких экранах переключаем на блочную модель */
  }
}

.form-group-section {
  width: 100%;
}

.form-filter {
  width: 100%;
  cursor: pointer;
}

.form-sort {
  width: 100%;
  cursor: pointer;
}

.form-filter button {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  background-color: #fff;
  color: #363636;
  border: none;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
}

.form-sort button {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  background-color: #fff;
  color: #363636;
  border: none;
  width: 100%;
  border-radius: 10px;
  padding: 12px;
  font-weight: 600;
  cursor: pointer;
}

.search-modals {
  display: flex;
  gap: 5px;
  width: 100%;
}

.modal-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  z-index: 10;
  inset: 0;
  background-color: black;
  opacity: 0.5;
}

.modal-content {
  position: fixed;
  opacity: 1;
  width: 60%;
  height: auto;
  z-index: 20;
  background-color: #fff;
  padding: 20px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .modal-content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 250px;
  }

  .search-modals {
    height: 100%;
    margin: 8px 0;
  }
}

.modal-heading {
  display: flex;
  justify-content: space-between;
}

.search-article {
  display: flex;
  align-items: center;
  gap: 3px;
  border-radius: 10px;
  border: 1px solid #908f95;
}

.search-article img {
  margin: 0 2px 0 8px;
}

.search-article .formInput[type="text"] {
  width: 100%;
  border: none;
  padding: 0 0 0 2px;
}

.search-article .formInput:focus {
  outline: none;
}

.search-article:focus {
  outline: 1px solid rgb(23, 124, 255);
  border: 1px solid rgb(23, 124, 255);
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 10px;
  border-radius: 10px;
}

.accordion-header label {
  font-weight: bold;
}

.close-button {
  background-color: #e3e2e2;
  border-radius: 100%;
  display: flex;
  align-items: center;
  color: #363636;
  padding: 8px;
}

.form-group label {
  display: flex;
  margin: 6px 0 6px;
  font-weight: bold;
}

.form-group-label {
  display: block;
  margin: 6px 0 8px;
  font-weight: bold;
}

.form-group-radio {
  display: flex;
  flex-direction: column;
  margin: 0 0 10px;
}

.form-group-radio input[type="radio"] {
  display: none;
}

.form-group-radio label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin: 10px 0 10px;
}

.form-group-radio label::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #908f95;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.form-group-radio input[type="radio"]:checked + label::before {
  border-color: rgb(23, 124, 255);
  border-width: 2px;
}

.form-group-radio label::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgb(23, 124, 255);
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.2s ease-in-out;
}

.form-group-radio input[type="radio"]:checked + label::after {
  transform: translate(24%, -51%) scale(1.5);
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #908f95;
  border-radius: 10px;
}

.form-group input:focus {
  outline: 1px solid rgb(23, 124, 255);
}

form .form-group input {
  margin: 10px 0 10px 0;
}

.formButton {
  margin-top: 10px;
  width: 100%;
  padding: 15px 0 15px;
  border-radius: 10px;
  font-weight: bold;
  background-color: rgb(23, 124, 255);
}

button {
  padding: 0.5rem 1rem;
  background-color: #565656;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.order-form-select-group .order-choise-btn-label:first-child::after {
  display: block;
  content: "";
  width: calc(100% - 62px);
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.container-order-data
  .order-form-select-group
  .order-choise-btn-label:last-child
  .choise-btn {
  background: url(../img/payment.svg) no-repeat 16px center / 30px 30px,
    url(../img/chevron-right-dark.svg) no-repeat calc(100% - 16px) center / 15px
      15px;
  border-radius: 0 0 10px 10px;
}

.container-order-data .order-form .delivery-description {
  margin-top: 10px;
  padding-top: 0;
  padding-left: 13px;
  color: #76757b;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 0 0 6px;
}

.order-form .order-data-page-title {
  margin: 24px 0 10px;
  padding-left: 13px;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: #76757b;
  letter-spacing: 0.015em;
  align-items: center;
  display: flex;
  gap: 12px;
}

.order-form .form-group-section {
  background-color: #363636 !important;
  border-radius: 10px;
  overflow: hidden;
}

.order-form .form-group-section .form-group {
  position: relative;
}

.order-form .form-group-section .form-group .formInput {
  color: #fff;
  background-color: #363636;
  border: none;
  border-radius: 0;
  font-size: 14px;
  padding: 13px 16px;
  width: 100%;
  display: block;
  outline: none;
}

.order-form .form-group:not(:last-child):after {
  display: block;
  content: "";
  width: calc(100% - 32px);
  height: 1px;
  background-color: #565656;
  position: absolute;
  right: 16px;
  bottom: 0;
}

.order-form .delivery-description {
  margin-top: 10px;
  padding-top: 0;
  padding-left: 13px;
  color: #76757b;
}

.delivery-description {
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 0 0 6px;
}

.text-red {
  color: #f1222e !important;
}

.button {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 12px;
  background-color: #20b53a;
}

.mainButton {
  font-size: 13px;
  text-align: center;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 -6px 16px -16px #ababab;
  background-color: #20b53a;
  /* border-radius: 14px 14px 0 0; */
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -1px auto;
  max-width: 600px;
  padding: 19px 12px 19px 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 101;
  color: white;
}

.banner {
  width: 100%;
  padding: 8px;
}

.banner img {
  width: 100%;
  border-radius: 16px;
}

.menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
}

.menu .menuIcon {
  background: #363636;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .menuContainer2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 4px;
  width: 100%;
} */

.bottomBar {
  display: flex;
  justify-content: space-between;
  background: #2a2a2a;
  padding: 12px;
  border-radius: 30px;
  align-items: center;
  width: 400px;
  margin: -1px auto;
  max-width: 480px;
  /* padding: 9px 12px 9px 12px; */
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  width: calc(100% - 24px);
  z-index: 1;
}

.bottomBar .icon {
  border-radius: 50%;
  background: #1c1c1c;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.card-count-number {
  position: absolute;
  min-width: 19px;
  min-height: 19px;
  aspect-ratio: 1;
  background-color: #1fb73a;
  border-radius: 50%;
  color: #ffffff;
  top: -5px;
  right: -5px;
  text-align: center;
  font-size: 14px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-order .go-to-order-wrap {
  bottom: 54px;
}

.container-order-data .go-to-order-wrap {
  bottom: 54px;
}

.dropdown {
  position: absolute;
  top: 71px;
  background: #363636;
  color: #fff;
  padding: 12px;
  gap: 6px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  right: 12px;
  min-width: 300px;
}

.dropdown .item {
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 320px) and (max-width: 480px) {
  .dropdown {
    min-width: calc(100% - 24px);
  }
}

.menuContainer2 {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  width: 100%;
}

.swiper-scrollbar-drag {
  background: rgb(31, 183, 58) !important;
}

.swiper-button-next,
.swiper-button-prev {
  color: rgb(31, 183, 58) !important;
}

.container-order .cart {
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
}

.container-order .cart-item-row .cart-item-data .cart-item-counter {
  width: 110px !important;
}

.catalog_counter .cic-minus {
  width: auto !important;
  padding-left: 10px !important;
}

.catalog_counter .cic-plus {
  width: auto !important;
  padding-right: 10px !important;
}

footer {
  /* padding-bottom: 24px; */
  box-sizing: content-box;
  max-width: 1500px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

footer .footer-text {
  text-align: center;
  width: 280px;
}

/* footer .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
